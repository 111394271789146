export const worldTagKeys = {
  abandonedColony: 'abandonedColony',
  alienRuins: 'alienRuins',
  alteredHumanity: 'alteredHumanity',
  anarchists: 'anarchists',
  anthropomorphs: 'anthropomorphs',
  area51: 'area51',
  badlandsWorld: 'badlandsWorld',
  battleground: 'battleground',
  beastmasters: 'beastmasters',
  bubbleCities: 'bubbleCities',
  cheapLife: 'cheapLife',
  civilWar: 'civilWar',
  coldWar: 'coldWar',
  colonizedPopulation: 'colonizedPopulation',
  culturalPower: 'culturalPower',
  cybercommunists: 'cybercommunists',
  cyborgs: 'cyborgs',
  cyclicalDoom: 'cyclicalDoom',
  desertWorld: 'desertWorld',
  doomedWorld: 'doomedWorld',
  dyingRace: 'dyingRace',
  eugenicCult: 'eugenicCult',
  exchangeConsulate: 'exchangeConsulate',
  fallenHegemon: 'fallenHegemon',
  feralWorld: 'feralWorld',
  flyingCities: 'flyingCities',
  forbiddenTech: 'forbiddenTech',
  formerWarriors: 'formerWarriors',
  freakGeology: 'freakGeology',
  freakWeather: 'freakWeather',
  friendlyFoe: 'friendlyFoe',
  goldRush: 'goldRush',
  greatWork: 'greatWork',
  hatred: 'hatred',
  heavyIndustry: 'heavyIndustry',
  heavyMining: 'heavyMining',
  hivemind: 'hivemind',
  holyWar: 'holyWar',
  hostileBiosphere: 'hostileBiosphere',
  hostileSpace: 'hostileSpace',
  immortals: 'immortals',
  localSpecialty: 'localSpecialty',
  localTech: 'localTech',
  majorSpaceyard: 'majorSpaceyard',
  mandarinate: 'mandarinate',
  mandateBase: 'mandateBase',
  maneaters: 'maneaters',
  megacorps: 'megacorps',
  mercenaries: 'mercenaries',
  minimalContact: 'minimalContact',
  misandryMisogyny: 'misandryMisogyny',
  nightWorld: 'nightWorld',
  nomads: 'nomads',
  oceanicWorld: 'oceanicWorld',
  outOfContact: 'outOfContact',
  outpostWorld: 'outpostWorld',
  perimeterAgency: 'perimeterAgency',
  pilgrimageSite: 'pilgrimageSite',
  pleasureWorld: 'pleasureWorld',
  policeState: 'policeState',
  postScarcity: 'postScarcity',
  preceptorArchive: 'preceptorArchive',
  pretechCultists: 'pretechCultists',
  primitiveAliens: 'primitiveAliens',
  prisonPlanet: 'prisonPlanet',
  psionicsAcademy: 'psionicsAcademy',
  psionicsFear: 'psionicsFear',
  psionicsWorship: 'psionicsWorship',
  quarantinedWorld: 'quarantinedWorld',
  radioactiveWorld: 'radioactiveWorld',
  refugees: 'refugees',
  regionalHegemon: 'regionalHegemon',
  restrictiveLaws: 'restrictiveLaws',
  revanchists: 'revanchists',
  revolutionaries: 'revolutionaries',
  rigidCulture: 'rigidCulture',
  risingHegemon: 'risingHegemon',
  ritualCombat: 'ritualCombat',
  robots: 'robots',
  seagoingCities: 'seagoingCities',
  sealedMenace: 'sealedMenace',
  secretMasters: 'secretMasters',
  sectarians: 'sectarians',
  seismicInstability: 'seismicInstability',
  shackledWorld: 'shackledWorld',
  societalDespair: 'societalDespair',
  soleSupplier: 'soleSupplier',
  tabooTreasure: 'tabooTreasure',
  terraformFailure: 'terraformFailure',
  theocracy: 'theocracy',
  tombWorld: 'tombWorld',
  tradeHub: 'tradeHub',
  tyranny: 'tyranny',
  unbrakedAi: 'unbrakedAi',
  urbanizedSurface: 'urbanizedSurface',
  utopia: 'utopia',
  warlords: 'warlords',
  xenophiles: 'xenophiles',
  xenophobes: 'xenophobes',
  zombies: 'zombies',
};

export default {
  [worldTagKeys.abandonedColony]: {
    key: worldTagKeys.abandonedColony,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.alienRuins]: {
    key: worldTagKeys.alienRuins,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 4,
    places: 4,
  },
  [worldTagKeys.alteredHumanity]: {
    key: worldTagKeys.alteredHumanity,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 4,
    places: 3,
  },
  [worldTagKeys.anarchists]: {
    key: worldTagKeys.anarchists,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.anthropomorphs]: {
    key: worldTagKeys.anthropomorphs,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.area51]: {
    key: worldTagKeys.area51,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.badlandsWorld]: {
    key: worldTagKeys.badlandsWorld,
    enemies: 3,
    friends: 3,
    complications: 4,
    things: 3,
    places: 3,
  },
  [worldTagKeys.battleground]: {
    key: worldTagKeys.battleground,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.beastmasters]: {
    key: worldTagKeys.beastmasters,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.bubbleCities]: {
    key: worldTagKeys.bubbleCities,
    enemies: 3,
    friends: 3,
    complications: 4,
    things: 3,
    places: 4,
  },
  [worldTagKeys.cheapLife]: {
    key: worldTagKeys.cheapLife,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.civilWar]: {
    key: worldTagKeys.civilWar,
    enemies: 5,
    friends: 3,
    complications: 3,
    things: 4,
    places: 4,
  },
  [worldTagKeys.coldWar]: {
    key: worldTagKeys.coldWar,
    enemies: 3,
    friends: 4,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.colonizedPopulation]: {
    key: worldTagKeys.colonizedPopulation,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.culturalPower]: {
    key: worldTagKeys.culturalPower,
    enemies: 4,
    friends: 4,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.cybercommunists]: {
    key: worldTagKeys.cybercommunists,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.cyborgs]: {
    key: worldTagKeys.cyborgs,
    enemies: 4,
    friends: 3,
    complications: 3,
    things: 4,
    places: 3,
  },
  [worldTagKeys.cyclicalDoom]: {
    key: worldTagKeys.cyclicalDoom,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.desertWorld]: {
    key: worldTagKeys.desertWorld,
    enemies: 5,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.doomedWorld]: {
    key: worldTagKeys.doomedWorld,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 4,
    places: 3,
  },
  [worldTagKeys.dyingRace]: {
    key: worldTagKeys.dyingRace,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.eugenicCult]: {
    key: worldTagKeys.eugenicCult,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 4,
    places: 3,
  },
  [worldTagKeys.exchangeConsulate]: {
    key: worldTagKeys.exchangeConsulate,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.fallenHegemon]: {
    key: worldTagKeys.fallenHegemon,
    enemies: 3,
    friends: 3,
    complications: 4,
    things: 3,
    places: 3,
  },
  [worldTagKeys.feralWorld]: {
    key: worldTagKeys.feralWorld,
    enemies: 5,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.flyingCities]: {
    key: worldTagKeys.flyingCities,
    enemies: 3,
    friends: 3,
    complications: 4,
    things: 3,
    places: 3,
  },
  [worldTagKeys.forbiddenTech]: {
    key: worldTagKeys.forbiddenTech,
    enemies: 3,
    friends: 4,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.formerWarriors]: {
    key: worldTagKeys.formerWarriors,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.freakGeology]: {
    key: worldTagKeys.freakGeology,
    enemies: 2,
    friends: 3,
    complications: 3,
    things: 3,
    places: 2,
  },
  [worldTagKeys.freakWeather]: {
    key: worldTagKeys.freakWeather,
    enemies: 3,
    friends: 2,
    complications: 2,
    things: 3,
    places: 3,
  },
  [worldTagKeys.friendlyFoe]: {
    key: worldTagKeys.friendlyFoe,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.goldRush]: {
    key: worldTagKeys.goldRush,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 3,
    places: 4,
  },
  [worldTagKeys.greatWork]: {
    key: worldTagKeys.greatWork,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.hatred]: {
    key: worldTagKeys.hatred,
    enemies: 2,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.heavyIndustry]: {
    key: worldTagKeys.heavyIndustry,
    enemies: 3,
    friends: 3,
    complications: 4,
    things: 3,
    places: 4,
  },
  [worldTagKeys.heavyMining]: {
    key: worldTagKeys.heavyMining,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.hivemind]: {
    key: worldTagKeys.hivemind,
    enemies: 3,
    friends: 3,
    complications: 5,
    things: 3,
    places: 3,
  },
  [worldTagKeys.holyWar]: {
    key: worldTagKeys.holyWar,
    enemies: 3,
    friends: 3,
    complications: 4,
    things: 3,
    places: 3,
  },
  [worldTagKeys.hostileBiosphere]: {
    key: worldTagKeys.hostileBiosphere,
    enemies: 4,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.hostileSpace]: {
    key: worldTagKeys.hostileSpace,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.immortals]: {
    key: worldTagKeys.immortals,
    enemies: 3,
    friends: 2,
    complications: 5,
    things: 3,
    places: 3,
  },
  [worldTagKeys.localSpecialty]: {
    key: worldTagKeys.localSpecialty,
    enemies: 3,
    friends: 3,
    complications: 4,
    things: 3,
    places: 3,
  },
  [worldTagKeys.localTech]: {
    key: worldTagKeys.localTech,
    enemies: 4,
    friends: 3,
    complications: 4,
    things: 4,
    places: 3,
  },
  [worldTagKeys.majorSpaceyard]: {
    key: worldTagKeys.majorSpaceyard,
    enemies: 4,
    friends: 3,
    complications: 4,
    things: 3,
    places: 3,
  },
  [worldTagKeys.mandarinate]: {
    key: worldTagKeys.mandarinate,
    enemies: 3,
    friends: 3,
    complications: 4,
    things: 3,
    places: 3,
  },
  [worldTagKeys.mandateBase]: {
    key: worldTagKeys.mandateBase,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.maneaters]: {
    key: worldTagKeys.maneaters,
    enemies: 3,
    friends: 3,
    complications: 4,
    things: 3,
    places: 3,
  },
  [worldTagKeys.megacorps]: {
    key: worldTagKeys.megacorps,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 4,
    places: 3,
  },
  [worldTagKeys.mercenaries]: {
    key: worldTagKeys.mercenaries,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.minimalContact]: {
    key: worldTagKeys.minimalContact,
    enemies: 3,
    friends: 4,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.misandryMisogyny]: {
    key: worldTagKeys.misandryMisogyny,
    enemies: 3,
    friends: 4,
    complications: 4,
    things: 3,
    places: 3,
  },
  [worldTagKeys.nightWorld]: {
    key: worldTagKeys.nightWorld,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.nomads]: {
    key: worldTagKeys.nomads,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.oceanicWorld]: {
    key: worldTagKeys.oceanicWorld,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 3,
    places: 4,
  },
  [worldTagKeys.outOfContact]: {
    key: worldTagKeys.outOfContact,
    enemies: 3,
    friends: 3,
    complications: 4,
    things: 3,
    places: 3,
  },
  [worldTagKeys.outpostWorld]: {
    key: worldTagKeys.outpostWorld,
    enemies: 3,
    friends: 3,
    complications: 4,
    things: 3,
    places: 4,
  },
  [worldTagKeys.perimeterAgency]: {
    key: worldTagKeys.perimeterAgency,
    enemies: 3,
    friends: 3,
    complications: 4,
    things: 3,
    places: 4,
  },
  [worldTagKeys.pilgrimageSite]: {
    key: worldTagKeys.pilgrimageSite,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.pleasureWorld]: {
    key: worldTagKeys.pleasureWorld,
    enemies: 3,
    friends: 3,
    complications: 4,
    things: 3,
    places: 3,
  },
  [worldTagKeys.policeState]: {
    key: worldTagKeys.policeState,
    enemies: 3,
    friends: 4,
    complications: 3,
    things: 3,
    places: 4,
  },
  [worldTagKeys.postScarcity]: {
    key: worldTagKeys.postScarcity,
    enemies: 3,
    friends: 3,
    complications: 4,
    things: 3,
    places: 3,
  },
  [worldTagKeys.preceptorArchive]: {
    key: worldTagKeys.preceptorArchive,
    enemies: 4,
    friends: 4,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.pretechCultists]: {
    key: worldTagKeys.pretechCultists,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.primitiveAliens]: {
    key: worldTagKeys.primitiveAliens,
    enemies: 4,
    friends: 4,
    complications: 4,
    things: 3,
    places: 3,
  },
  [worldTagKeys.prisonPlanet]: {
    key: worldTagKeys.prisonPlanet,
    enemies: 3,
    friends: 3,
    complications: 4,
    things: 3,
    places: 3,
  },
  [worldTagKeys.psionicsAcademy]: {
    key: worldTagKeys.psionicsAcademy,
    enemies: 4,
    friends: 3,
    complications: 5,
    things: 3,
    places: 4,
  },
  [worldTagKeys.psionicsFear]: {
    key: worldTagKeys.psionicsFear,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.psionicsWorship]: {
    key: worldTagKeys.psionicsWorship,
    enemies: 4,
    friends: 3,
    complications: 4,
    things: 3,
    places: 3,
  },
  [worldTagKeys.quarantinedWorld]: {
    key: worldTagKeys.quarantinedWorld,
    enemies: 3,
    friends: 3,
    complications: 4,
    things: 3,
    places: 3,
  },
  [worldTagKeys.radioactiveWorld]: {
    key: worldTagKeys.radioactiveWorld,
    enemies: 3,
    friends: 3,
    complications: 4,
    things: 3,
    places: 4,
  },
  [worldTagKeys.refugees]: {
    key: worldTagKeys.refugees,
    enemies: 3,
    friends: 3,
    complications: 5,
    things: 4,
    places: 3,
  },
  [worldTagKeys.regionalHegemon]: {
    key: worldTagKeys.regionalHegemon,
    enemies: 3,
    friends: 3,
    complications: 4,
    things: 3,
    places: 4,
  },
  [worldTagKeys.restrictiveLaws]: {
    key: worldTagKeys.restrictiveLaws,
    enemies: 4,
    friends: 3,
    complications: 4,
    things: 3,
    places: 4,
  },
  [worldTagKeys.revanchists]: {
    key: worldTagKeys.revanchists,
    enemies: 4,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.revolutionaries]: {
    key: worldTagKeys.revolutionaries,
    enemies: 3,
    friends: 3,
    complications: 4,
    things: 3,
    places: 3,
  },
  [worldTagKeys.rigidCulture]: {
    key: worldTagKeys.rigidCulture,
    enemies: 4,
    friends: 3,
    complications: 4,
    things: 3,
    places: 4,
  },
  [worldTagKeys.risingHegemon]: {
    key: worldTagKeys.risingHegemon,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.ritualCombat]: {
    key: worldTagKeys.ritualCombat,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 4,
    places: 3,
  },
  [worldTagKeys.robots]: {
    key: worldTagKeys.robots,
    enemies: 4,
    friends: 3,
    complications: 4,
    things: 4,
    places: 3,
  },
  [worldTagKeys.seagoingCities]: {
    key: worldTagKeys.seagoingCities,
    enemies: 4,
    friends: 4,
    complications: 4,
    things: 3,
    places: 3,
  },
  [worldTagKeys.sealedMenace]: {
    key: worldTagKeys.sealedMenace,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.secretMasters]: {
    key: worldTagKeys.secretMasters,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.sectarians]: {
    key: worldTagKeys.sectarians,
    enemies: 3,
    friends: 4,
    complications: 5,
    things: 3,
    places: 4,
  },
  [worldTagKeys.seismicInstability]: {
    key: worldTagKeys.seismicInstability,
    enemies: 4,
    friends: 3,
    complications: 4,
    things: 4,
    places: 4,
  },
  [worldTagKeys.shackledWorld]: {
    key: worldTagKeys.shackledWorld,
    enemies: 4,
    friends: 3,
    complications: 4,
    things: 3,
    places: 3,
  },
  [worldTagKeys.societalDespair]: {
    key: worldTagKeys.societalDespair,
    enemies: 3,
    friends: 3,
    complications: 4,
    things: 3,
    places: 3,
  },
  [worldTagKeys.soleSupplier]: {
    key: worldTagKeys.soleSupplier,
    enemies: 4,
    friends: 4,
    complications: 4,
    things: 3,
    places: 3,
  },
  [worldTagKeys.tabooTreasure]: {
    key: worldTagKeys.tabooTreasure,
    enemies: 4,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.terraformFailure]: {
    key: worldTagKeys.terraformFailure,
    enemies: 3,
    friends: 3,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.theocracy]: {
    key: worldTagKeys.theocracy,
    enemies: 4,
    friends: 4,
    complications: 4,
    things: 4,
    places: 4,
  },
  [worldTagKeys.tombWorld]: {
    key: worldTagKeys.tombWorld,
    enemies: 4,
    friends: 4,
    complications: 4,
    things: 4,
    places: 5,
  },
  [worldTagKeys.tradeHub]: {
    key: worldTagKeys.tradeHub,
    enemies: 4,
    friends: 4,
    complications: 4,
    things: 4,
    places: 4,
  },
  [worldTagKeys.tyranny]: {
    key: worldTagKeys.tyranny,
    enemies: 4,
    friends: 4,
    complications: 3,
    things: 3,
    places: 4,
  },
  [worldTagKeys.unbrakedAi]: {
    key: worldTagKeys.unbrakedAi,
    enemies: 3,
    friends: 3,
    complications: 5,
    things: 4,
    places: 4,
  },
  [worldTagKeys.urbanizedSurface]: {
    key: worldTagKeys.urbanizedSurface,
    enemies: 3,
    friends: 4,
    complications: 3,
    things: 3,
    places: 3,
  },
  [worldTagKeys.utopia]: {
    key: worldTagKeys.utopia,
    enemies: 3,
    friends: 3,
    complications: 4,
    things: 3,
    places: 3,
  },
  [worldTagKeys.warlords]: {
    key: worldTagKeys.warlords,
    enemies: 4,
    friends: 4,
    complications: 4,
    things: 4,
    places: 4,
  },
  [worldTagKeys.xenophiles]: {
    key: worldTagKeys.xenophiles,
    enemies: 3,
    friends: 3,
    complications: 4,
    things: 3,
    places: 3,
  },
  [worldTagKeys.xenophobes]: {
    key: worldTagKeys.xenophobes,
    enemies: 3,
    friends: 3,
    complications: 4,
    things: 3,
    places: 3,
  },
  [worldTagKeys.zombies]: {
    key: worldTagKeys.zombies,
    enemies: 3,
    friends: 3,
    complications: 4,
    things: 3,
    places: 3,
  },
};
