export const FACTION_GOALS = {
  military: 'military',
  commercial: 'commercial',
  coup: 'coup',
  seizure: 'seizure',
  influence: 'influence',
  blood: 'blood',
  peaceable: 'peaceable',
  destroy: 'destroy',
  inside: 'inside',
  valor: 'valor',
  wealth: 'wealth',
};

export const FACTION_TAGS = {
  colonists: 'colonists',
  deepRooted: 'deepRooted',
  eugenicsCult: 'eugenicsCult',
  exchangeConsulate: 'exchangeConsulate',
  fanatical: 'fanatical',
  imperialists: 'imperialists',
  machiavellian: 'machiavellian',
  mercenaryGroup: 'mercenaryGroup',
  perimeterAgency: 'perimeterAgency',
  pirates: 'pirates',
  planetaryGovernment: 'planetaryGovernment',
  plutocratic: 'plutocratic',
  preceptorArchive: 'preceptorArchive',
  psychicAcademy: 'psychicAcademy',
  savage: 'savage',
  scavengers: 'scavengers',
  secretive: 'secretive',
  technicalExpertise: 'technicalExpertise',
  theocratic: 'theocratic',
  warlike: 'warlike',
};

export const FACTION_ASSET_CATEGORIES = {
  special: 'special',
  cunning: 'cunning',
  force: 'force',
  wealth: 'wealth',
};

export const FACTION_ASSET_TYPES = {
  facility: 'facility',
  logisticsFacility: 'logisticsFacility',
  militaryUnit: 'militaryUnit',
  spaceship: 'spaceship',
  special: 'special',
  specialForces: 'specialForces',
  starship: 'starship',
  tactic: 'tactic',
};

export const FACTION_ASSET_NOTES = {
  planetaryApproval: 'planetaryApproval',
  specialAction: 'specialAction',
  specialFeatureCost: 'specialFeatureCost',
};

export const FACTION_ASSETS = {
  baseOfInfluence: {
    key: 'baseOfInfluence',
    category: FACTION_ASSET_CATEGORIES.special,
    rating: 1,
    hp: '*',
    cost: '*',
    techLevel: 0,
    type: FACTION_ASSET_TYPES.special,
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  blackmail: {
    key: 'blackmail',
    category: FACTION_ASSET_CATEGORIES.cunning,
    rating: 2,
    hp: 4,
    cost: 4,
    techLevel: 0,
    type: FACTION_ASSET_TYPES.tactic,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.cunning,
      defense: FACTION_ASSET_CATEGORIES.cunning,
      damage: {
        dice: '1d4',
        add: 1,
      },
    },
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  boltholes: {
    key: 'boltholes',
    category: FACTION_ASSET_CATEGORIES.cunning,
    rating: 5,
    hp: 6,
    cost: 12,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.logisticsFacility,
    counter: {
      dice: '2d6',
    },
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  bookOfSecrets: {
    key: 'bookOfSecrets',
    category: FACTION_ASSET_CATEGORIES.cunning,
    rating: 7,
    hp: 10,
    cost: 20,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.tactic,
    counter: {
      dice: '2d8',
    },
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  covertShipping: {
    key: 'covertShipping',
    category: FACTION_ASSET_CATEGORIES.cunning,
    rating: 3,
    hp: 4,
    cost: 8,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.logisticsFacility,
    notes: [
      FACTION_ASSET_NOTES.specialAction,
      FACTION_ASSET_NOTES.specialFeatureCost,
    ],
  },
  covertTransitNet: {
    key: 'covertTransitNet',
    category: FACTION_ASSET_CATEGORIES.cunning,
    rating: 6,
    hp: 15,
    cost: 18,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.logisticsFacility,
    notes: [FACTION_ASSET_NOTES.specialAction],
  },
  crackedComms: {
    key: 'crackedComms',
    category: FACTION_ASSET_CATEGORIES.cunning,
    rating: 5,
    hp: 6,
    cost: 14,
    techLevel: 0,
    type: FACTION_ASSET_TYPES.tactic,
    counter: {
      other: 'special',
    },
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  cyberninjas: {
    key: 'cyberninjas',
    category: FACTION_ASSET_CATEGORIES.cunning,
    rating: 3,
    hp: 4,
    cost: 6,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.specialForces,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.cunning,
      defense: FACTION_ASSET_CATEGORIES.cunning,
      damage: {
        dice: '2d6',
      },
    },
  },
  demagogues: {
    key: 'demagogues',
    category: FACTION_ASSET_CATEGORIES.cunning,
    rating: 6,
    hp: 10,
    cost: 20,
    techLevel: 0,
    type: FACTION_ASSET_TYPES.specialForces,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.cunning,
      defense: FACTION_ASSET_CATEGORIES.cunning,
      damage: {
        dice: '2d8',
      },
    },
    counter: {
      dice: '1d8',
    },
  },
  falseFront: {
    key: 'falseFront',
    category: FACTION_ASSET_CATEGORIES.cunning,
    rating: 1,
    hp: 2,
    cost: 1,
    techLevel: 0,
    type: FACTION_ASSET_TYPES.logisticsFacility,
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  informers: {
    key: 'informers',
    category: FACTION_ASSET_CATEGORIES.cunning,
    rating: 1,
    hp: 3,
    cost: 2,
    techLevel: 0,
    type: FACTION_ASSET_TYPES.specialForces,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.cunning,
      defense: FACTION_ASSET_CATEGORIES.cunning,
      damage: {
        other: 'special',
      },
    },
    notes: [
      FACTION_ASSET_NOTES.specialAction,
      FACTION_ASSET_NOTES.specialFeatureCost,
    ],
  },
  lobbyists: {
    key: 'lobbyists',
    category: FACTION_ASSET_CATEGORIES.cunning,
    rating: 2,
    hp: 4,
    cost: 4,
    techLevel: 0,
    type: FACTION_ASSET_TYPES.specialForces,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.cunning,
      defense: FACTION_ASSET_CATEGORIES.cunning,
      damage: {
        other: 'special',
      },
    },
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  organizationMoles: {
    key: 'organizationMoles',
    category: FACTION_ASSET_CATEGORIES.cunning,
    rating: 5,
    hp: 8,
    cost: 10,
    techLevel: 0,
    type: FACTION_ASSET_TYPES.tactic,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.cunning,
      defense: FACTION_ASSET_CATEGORIES.cunning,
      damage: {
        dice: '2d6',
      },
    },
  },
  panopticonMatrix: {
    key: 'panopticonMatrix',
    category: FACTION_ASSET_CATEGORIES.cunning,
    rating: 8,
    hp: 20,
    cost: 30,
    techLevel: 5,
    type: FACTION_ASSET_TYPES.logisticsFacility,
    counter: {
      dice: '1d6',
    },
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  partyMachines: {
    key: 'partyMachines',
    category: FACTION_ASSET_CATEGORIES.cunning,
    upkeep: -1,
    rating: 4,
    hp: 10,
    cost: 8,
    techLevel: 0,
    type: FACTION_ASSET_TYPES.logisticsFacility,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.cunning,
      defense: FACTION_ASSET_CATEGORIES.cunning,
      damage: {
        dice: '2d6',
      },
    },
    counter: {
      dice: '1d6',
    },
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  popularMovements: {
    key: 'popularMovements',
    category: FACTION_ASSET_CATEGORIES.cunning,
    rating: 7,
    hp: 16,
    cost: 25,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.tactic,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.cunning,
      defense: FACTION_ASSET_CATEGORIES.cunning,
      damage: {
        dice: '2d6',
      },
    },
    counter: {
      dice: '1d6',
    },
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  saboteurs: {
    key: 'saboteurs',
    category: FACTION_ASSET_CATEGORIES.cunning,
    rating: 2,
    hp: 6,
    cost: 5,
    techLevel: 0,
    type: FACTION_ASSET_TYPES.specialForces,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.cunning,
      defense: FACTION_ASSET_CATEGORIES.cunning,
      damage: {
        dice: '2d4',
      },
    },
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  seditionists: {
    key: 'seditionists',
    category: FACTION_ASSET_CATEGORIES.cunning,
    rating: 4,
    hp: 8,
    cost: 12,
    techLevel: 0,
    type: FACTION_ASSET_TYPES.specialForces,
    notes: [FACTION_ASSET_NOTES.specialAction],
  },
  seductresses: {
    key: 'seductresses',
    category: FACTION_ASSET_CATEGORIES.cunning,
    rating: 2,
    hp: 4,
    cost: 4,
    techLevel: 0,
    type: FACTION_ASSET_TYPES.specialForces,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.cunning,
      defense: FACTION_ASSET_CATEGORIES.cunning,
      damage: {
        other: 'special',
      },
    },
    notes: [
      FACTION_ASSET_NOTES.specialAction,
      FACTION_ASSET_NOTES.specialFeatureCost,
    ],
  },
  smugglers: {
    key: 'smugglers',
    category: FACTION_ASSET_CATEGORIES.cunning,
    rating: 1,
    hp: 4,
    cost: 2,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.starship,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.cunning,
      defense: FACTION_ASSET_CATEGORIES.wealth,
      damage: {
        dice: '1d4',
      },
    },
    notes: [FACTION_ASSET_NOTES.specialAction],
  },
  stealth: {
    key: 'stealth',
    category: FACTION_ASSET_CATEGORIES.cunning,
    rating: 3,
    cost: 2,
    techLevel: 0,
    type: FACTION_ASSET_TYPES.tactic,
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  transportLockdown: {
    key: 'transportLockdown',
    category: FACTION_ASSET_CATEGORIES.cunning,
    rating: 6,
    hp: 10,
    cost: 20,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.tactic,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.cunning,
      defense: FACTION_ASSET_CATEGORIES.cunning,
      damage: {
        other: 'special',
      },
    },
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  treachery: {
    key: 'treachery',
    category: FACTION_ASSET_CATEGORIES.cunning,
    rating: 7,
    hp: 5,
    cost: 10,
    techLevel: 0,
    type: FACTION_ASSET_TYPES.tactic,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.cunning,
      defense: FACTION_ASSET_CATEGORIES.cunning,
      damage: {
        other: 'special',
      },
    },
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  tripwireCells: {
    key: 'tripwireCells',
    category: FACTION_ASSET_CATEGORIES.cunning,
    rating: 4,
    hp: 8,
    cost: 12,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.specialForces,
    counter: {
      dice: '1d4',
    },
    notes: [
      FACTION_ASSET_NOTES.specialAction,
      FACTION_ASSET_NOTES.specialFeatureCost,
    ],
  },
  vanguardCadres: {
    key: 'vanguardCadres',
    category: FACTION_ASSET_CATEGORIES.cunning,
    rating: 4,
    hp: 12,
    cost: 8,
    techLevel: 3,
    type: FACTION_ASSET_TYPES.militaryUnit,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.cunning,
      defense: FACTION_ASSET_CATEGORIES.cunning,
      damage: {
        dice: '1d6',
      },
    },
    counter: {
      dice: '1d6',
    },
  },
  beachheadLanders: {
    key: 'beachheadLanders',
    category: FACTION_ASSET_CATEGORIES.force,
    rating: 4,
    hp: 10,
    cost: 10,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.facility,
    notes: [FACTION_ASSET_NOTES.specialAction],
  },
  blockadeFleets: {
    key: 'blockadeFleets',
    category: FACTION_ASSET_CATEGORIES.force,
    rating: 5,
    hp: 8,
    cost: 10,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.starship,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.force,
      defense: FACTION_ASSET_CATEGORIES.wealth,
      damage: {
        dice: '1d8',
        special: '*',
      },
    },
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  capitalFleets: {
    key: 'capitalFleets',
    category: FACTION_ASSET_CATEGORIES.force,
    upkeep: 2,
    rating: 8,
    hp: 30,
    cost: 40,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.spaceship,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.force,
      defense: FACTION_ASSET_CATEGORIES.force,
      damage: {
        dice: '3d10',
        add: 4,
      },
    },
    counter: {
      dice: '3d8',
    },
    notes: [
      FACTION_ASSET_NOTES.specialAction,
      FACTION_ASSET_NOTES.specialFeatureCost,
    ],
  },
  counterintelUnits: {
    key: 'counterintelUnits',
    category: FACTION_ASSET_CATEGORIES.force,
    rating: 3,
    hp: 4,
    cost: 6,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.specialForces,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.cunning,
      defense: FACTION_ASSET_CATEGORIES.cunning,
      damage: {
        dice: '1d4',
        add: 1,
      },
    },
    counter: {
      dice: '1d6',
    },
  },
  cunningTraps: {
    key: 'cunningTraps',
    category: FACTION_ASSET_CATEGORIES.force,
    rating: 3,
    hp: 2,
    cost: 5,
    techLevel: 0,
    type: FACTION_ASSET_TYPES.tactic,
    counter: {
      dice: '1d6',
      add: 3,
    },
  },
  deepStrikeLanders: {
    key: 'deepStrikeLanders',
    category: FACTION_ASSET_CATEGORIES.force,
    rating: 7,
    hp: 10,
    cost: 25,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.facility,
    notes: [FACTION_ASSET_NOTES.specialAction],
  },
  eliteSkirmishers: {
    key: 'eliteSkirmishers',
    category: FACTION_ASSET_CATEGORIES.force,
    rating: 2,
    hp: 5,
    cost: 5,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.militaryUnit,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.force,
      defense: FACTION_ASSET_CATEGORIES.force,
      damage: {
        dice: '2d4',
      },
    },
    counter: {
      dice: '1d4',
      add: 1,
    },
    notes: [FACTION_ASSET_NOTES.planetaryApproval],
  },
  extendedTheater: {
    key: 'extendedTheater',
    category: FACTION_ASSET_CATEGORIES.force,
    rating: 4,
    hp: 10,
    cost: 10,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.facility,
    notes: [FACTION_ASSET_NOTES.specialAction],
  },
  gengineeredSlaves: {
    key: 'gengineeredSlaves',
    category: FACTION_ASSET_CATEGORIES.force,
    rating: 1,
    hp: 6,
    cost: 2,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.militaryUnit,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.force,
      defense: FACTION_ASSET_CATEGORIES.force,
      damage: {
        dice: '1d6',
        add: 1,
      },
    },
    counter: {
      dice: '1d4',
    },
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  gravtankFormations: {
    key: 'gravtankFormations',
    category: FACTION_ASSET_CATEGORIES.force,
    rating: 6,
    hp: 14,
    cost: 24,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.militaryUnit,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.force,
      defense: FACTION_ASSET_CATEGORIES.force,
      damage: {
        dice: '2d10',
        add: 4,
      },
    },
    counter: {
      dice: '1d10',
    },
    notes: [FACTION_ASSET_NOTES.planetaryApproval],
  },
  guerrillaPopulace: {
    key: 'guerrillaPopulace',
    category: FACTION_ASSET_CATEGORIES.force,
    rating: 2,
    hp: 6,
    cost: 4,
    techLevel: 0,
    type: FACTION_ASSET_TYPES.militaryUnit,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.force,
      defense: FACTION_ASSET_CATEGORIES.cunning,
      damage: {
        dice: '1d4',
        add: 1,
      },
    },
  },
  hardenedPersonnel: {
    key: 'hardenedPersonnel',
    category: FACTION_ASSET_CATEGORIES.force,
    rating: 2,
    hp: 4,
    cost: 4,
    techLevel: 3,
    type: FACTION_ASSET_TYPES.specialForces,
    counter: {
      dice: '1d4',
      add: 1,
    },
  },
  heavyDropAssets: {
    key: 'heavyDropAssets',
    category: FACTION_ASSET_CATEGORIES.force,
    rating: 2,
    hp: 6,
    cost: 4,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.facility,
    notes: [FACTION_ASSET_NOTES.specialAction],
  },
  hitmen: {
    key: 'hitmen',
    category: FACTION_ASSET_CATEGORIES.force,
    rating: 1,
    hp: 1,
    cost: 2,
    techLevel: 0,
    type: FACTION_ASSET_TYPES.specialForces,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.force,
      defense: FACTION_ASSET_CATEGORIES.cunning,
      damage: {
        dice: '1d6',
      },
    },
  },
  integralProtocols: {
    key: 'integralProtocols',
    category: FACTION_ASSET_CATEGORIES.force,
    rating: 7,
    hp: 10,
    cost: 20,
    techLevel: 5,
    type: FACTION_ASSET_TYPES.facility,
    counter: {
      dice: '2d8',
      add: 2,
      other: '*',
    },
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  militiaUnits: {
    key: 'militiaUnits',
    category: FACTION_ASSET_CATEGORIES.force,
    rating: 1,
    hp: 4,
    cost: 4,
    techLevel: 3,
    type: FACTION_ASSET_TYPES.militaryUnit,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.force,
      defense: FACTION_ASSET_CATEGORIES.force,
      damage: {
        dice: '1d6',
      },
    },
    counter: {
      dice: '1d4',
      add: 1,
    },
    notes: [FACTION_ASSET_NOTES.planetaryApproval],
  },
  planetaryDefenses: {
    key: 'planetaryDefenses',
    category: FACTION_ASSET_CATEGORIES.force,
    rating: 6,
    hp: 20,
    cost: 18,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.facility,
    counter: {
      dice: '2d6',
      add: 2,
      special: '*',
    },
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  postechInfantry: {
    key: 'postechInfantry',
    category: FACTION_ASSET_CATEGORIES.force,
    rating: 4,
    hp: 12,
    cost: 8,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.militaryUnit,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.force,
      defense: FACTION_ASSET_CATEGORIES.force,
      damage: {
        dice: '1d8',
      },
    },
    counter: {
      dice: '1d8',
    },
    notes: [FACTION_ASSET_NOTES.planetaryApproval],
  },
  pretechInfantry: {
    key: 'pretechInfantry',
    category: FACTION_ASSET_CATEGORIES.force,
    rating: 6,
    hp: 16,
    cost: 20,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.militaryUnit,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.force,
      defense: FACTION_ASSET_CATEGORIES.force,
      damage: {
        dice: '2d8',
      },
    },
    counter: {
      dice: '2d8',
      add: 2,
    },
    notes: [FACTION_ASSET_NOTES.planetaryApproval],
  },
  pretechLogistics: {
    key: 'pretechLogistics',
    category: FACTION_ASSET_CATEGORIES.force,
    rating: 5,
    hp: 6,
    cost: 14,
    techLevel: 0,
    type: FACTION_ASSET_TYPES.facility,
    notes: [FACTION_ASSET_NOTES.specialAction],
  },
  psychicAssassins: {
    key: 'psychicAssassins',
    category: FACTION_ASSET_CATEGORIES.force,
    rating: 5,
    hp: 4,
    cost: 12,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.specialForces,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.cunning,
      defense: FACTION_ASSET_CATEGORIES.cunning,
      damage: {
        dice: '2d6',
        add: 2,
      },
    },
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  securityPersonnel: {
    key: 'securityPersonnel',
    category: FACTION_ASSET_CATEGORIES.force,
    rating: 1,
    hp: 3,
    cost: 2,
    techLevel: 0,
    type: FACTION_ASSET_TYPES.militaryUnit,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.force,
      defense: FACTION_ASSET_CATEGORIES.force,
      damage: {
        dice: '1d3',
        add: 1,
      },
    },
    counter: {
      dice: '1d4',
    },
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  spaceMarines: {
    key: 'spaceMarines',
    category: FACTION_ASSET_CATEGORIES.force,
    rating: 7,
    hp: 16,
    cost: 30,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.militaryUnit,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.force,
      defense: FACTION_ASSET_CATEGORIES.force,
      damage: {
        dice: '2d8',
        add: 2,
      },
    },
    counter: {
      dice: '2d8',
    },
    notes: [FACTION_ASSET_NOTES.specialAction],
  },
  strikeFleets: {
    key: 'strikeFleets',
    category: FACTION_ASSET_CATEGORIES.force,
    rating: 4,
    hp: 8,
    cost: 12,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.starship,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.force,
      defense: FACTION_ASSET_CATEGORIES.force,
      damage: {
        dice: '2d6',
      },
    },
    counter: {
      dice: '1d8',
    },
    notes: [FACTION_ASSET_NOTES.specialAction],
  },
  zealots: {
    key: 'zealots',
    category: FACTION_ASSET_CATEGORIES.force,
    rating: 3,
    hp: 4,
    cost: 6,
    techLevel: 0,
    type: FACTION_ASSET_TYPES.specialForces,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.force,
      defense: FACTION_ASSET_CATEGORIES.force,
      damage: {
        dice: '2d6',
      },
    },
    counter: {
      dice: '2d6',
    },
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  bankAssets: {
    key: 'bankAssets',
    category: FACTION_ASSET_CATEGORIES.wealth,
    rating: 4,
    hp: 8,
    cost: 12,
    techLevel: 3,
    type: FACTION_ASSET_TYPES.facility,
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  blockadeRunners: {
    key: 'blockadeRunners',
    category: FACTION_ASSET_CATEGORIES.wealth,
    rating: 5,
    hp: 6,
    cost: 12,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.starship,
    counter: {
      dice: '2d4',
    },
    notes: [FACTION_ASSET_NOTES.specialAction],
  },
  commoditiesBrokers: {
    key: 'commoditiesBrokers',
    category: FACTION_ASSET_CATEGORIES.wealth,
    rating: 6,
    hp: 10,
    cost: 20,
    techLevel: 0,
    type: FACTION_ASSET_TYPES.specialForces,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.wealth,
      defense: FACTION_ASSET_CATEGORIES.wealth,
      damage: {
        dice: '2d8',
      },
    },
    counter: {
      dice: '1d8',
    },
    notes: [FACTION_ASSET_NOTES.specialAction],
  },
  franchise: {
    key: 'franchise',
    category: FACTION_ASSET_CATEGORIES.wealth,
    rating: 1,
    hp: 3,
    cost: 2,
    techLevel: 2,
    type: FACTION_ASSET_TYPES.facility,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.wealth,
      defense: FACTION_ASSET_CATEGORIES.wealth,
      damage: {
        dice: '1d4',
      },
    },
    counter: {
      dice: '1d4',
      add: -1,
    },
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  freighterContract: {
    key: 'freighterContract',
    category: FACTION_ASSET_CATEGORIES.wealth,
    rating: 2,
    hp: 4,
    cost: 5,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.starship,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.wealth,
      defense: FACTION_ASSET_CATEGORIES.wealth,
      damage: {
        dice: '1d4',
      },
    },
    notes: [FACTION_ASSET_NOTES.specialAction],
  },
  harvesters: {
    key: 'harvesters',
    category: FACTION_ASSET_CATEGORIES.wealth,
    rating: 1,
    hp: 4,
    cost: 2,
    techLevel: 0,
    type: FACTION_ASSET_TYPES.facility,
    counter: {
      dice: '1d4',
    },
    notes: [FACTION_ASSET_NOTES.specialAction],
  },
  hostileTakeover: {
    key: 'hostileTakeover',
    category: FACTION_ASSET_CATEGORIES.wealth,
    rating: 7,
    hp: 10,
    cost: 20,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.tactic,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.wealth,
      defense: FACTION_ASSET_CATEGORIES.wealth,
      damage: {
        dice: '2d10',
      },
    },
    counter: {
      dice: '2d8',
    },
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  laboratory: {
    key: 'laboratory',
    category: FACTION_ASSET_CATEGORIES.wealth,
    rating: 3,
    hp: 4,
    cost: 6,
    techLevel: 0,
    type: FACTION_ASSET_TYPES.facility,
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  lawyers: {
    key: 'lawyers',
    category: FACTION_ASSET_CATEGORIES.wealth,
    rating: 2,
    hp: 4,
    cost: 6,
    techLevel: 0,
    type: FACTION_ASSET_TYPES.specialForces,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.cunning,
      defense: FACTION_ASSET_CATEGORIES.wealth,
      damage: {
        dice: '2d4',
      },
    },
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  localInvestments: {
    key: 'localInvestments',
    category: FACTION_ASSET_CATEGORIES.wealth,
    rating: 1,
    hp: 2,
    cost: 1,
    techLevel: 2,
    type: FACTION_ASSET_TYPES.facility,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.wealth,
      defense: FACTION_ASSET_CATEGORIES.wealth,
      damage: {
        dice: '1d4',
        add: -1,
      },
    },
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  marketers: {
    key: 'marketers',
    category: FACTION_ASSET_CATEGORIES.wealth,
    rating: 5,
    hp: 8,
    cost: 10,
    techLevel: 0,
    type: FACTION_ASSET_TYPES.tactic,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.cunning,
      defense: FACTION_ASSET_CATEGORIES.wealth,
      damage: {
        dice: '1d6',
      },
    },
    notes: [FACTION_ASSET_NOTES.specialAction],
  },
  medicalCenter: {
    key: 'medicalCenter',
    category: FACTION_ASSET_CATEGORIES.wealth,
    rating: 4,
    hp: 8,
    cost: 12,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.facility,
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  mercenaries: {
    key: 'mercenaries',
    category: FACTION_ASSET_CATEGORIES.wealth,
    upkeep: 1,
    rating: 3,
    hp: 6,
    cost: 8,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.militaryUnit,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.wealth,
      defense: FACTION_ASSET_CATEGORIES.force,
      damage: {
        dice: '2d4',
        add: 2,
      },
    },
    counter: {
      dice: '1d6',
    },
    notes: [
      FACTION_ASSET_NOTES.specialAction,
      FACTION_ASSET_NOTES.planetaryApproval,
      FACTION_ASSET_NOTES.specialFeatureCost,
    ],
  },
  monopoly: {
    key: 'monopoly',
    category: FACTION_ASSET_CATEGORIES.wealth,
    rating: 4,
    hp: 12,
    cost: 8,
    techLevel: 3,
    type: FACTION_ASSET_TYPES.facility,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.wealth,
      defense: FACTION_ASSET_CATEGORIES.wealth,
      damage: {
        dice: '1d6',
      },
    },
    counter: {
      dice: '1d6',
    },
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  postechIndustry: {
    key: 'postechIndustry',
    category: FACTION_ASSET_CATEGORIES.wealth,
    rating: 3,
    hp: 4,
    cost: 8,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.facility,
    counter: {
      dice: '1d4',
    },
    notes: [FACTION_ASSET_NOTES.specialAction],
  },
  pretechManufactories: {
    key: 'pretechManufactories',
    category: FACTION_ASSET_CATEGORIES.wealth,
    rating: 7,
    hp: 16,
    cost: 25,
    techLevel: 5,
    type: FACTION_ASSET_TYPES.facility,
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  pretechResearchers: {
    key: 'pretechResearchers',
    category: FACTION_ASSET_CATEGORIES.wealth,
    upkeep: 1,
    rating: 5,
    hp: 6,
    cost: 14,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.specialForces,
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  randdDepartments: {
    key: 'randdDepartments',
    category: FACTION_ASSET_CATEGORIES.wealth,
    rating: 6,
    hp: 15,
    cost: 18,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.facility,
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  scavengerFleets: {
    key: 'scavengerFleets',
    category: FACTION_ASSET_CATEGORIES.wealth,
    upkeep: 2,
    rating: 8,
    hp: 20,
    cost: 30,
    techLevel: 5,
    type: FACTION_ASSET_TYPES.starship,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.wealth,
      defense: FACTION_ASSET_CATEGORIES.wealth,
      damage: {
        dice: '2d10',
        add: 4,
      },
    },
    counter: {
      dice: '2d10',
    },
  },
  shippingCombine: {
    key: 'shippingCombine',
    category: FACTION_ASSET_CATEGORIES.wealth,
    rating: 4,
    hp: 10,
    cost: 10,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.facility,
    counter: {
      dice: '1d6',
    },
    notes: [FACTION_ASSET_NOTES.specialAction],
  },
  surveyors: {
    key: 'surveyors',
    category: FACTION_ASSET_CATEGORIES.wealth,
    rating: 2,
    hp: 4,
    cost: 4,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.specialForces,
    counter: {
      dice: '1d4',
    },
    notes: [
      FACTION_ASSET_NOTES.specialAction,
      FACTION_ASSET_NOTES.specialFeatureCost,
    ],
  },
  transitWeb: {
    key: 'transitWeb',
    category: FACTION_ASSET_CATEGORIES.wealth,
    rating: 7,
    hp: 5,
    cost: 15,
    techLevel: 5,
    type: FACTION_ASSET_TYPES.facility,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.cunning,
      defense: FACTION_ASSET_CATEGORIES.cunning,
      damage: {
        dice: '1d12',
      },
    },
    notes: [FACTION_ASSET_NOTES.specialFeatureCost],
  },
  unionToughs: {
    key: 'unionToughs',
    category: FACTION_ASSET_CATEGORIES.wealth,
    rating: 2,
    hp: 6,
    cost: 4,
    techLevel: 0,
    type: FACTION_ASSET_TYPES.militaryUnit,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.wealth,
      defense: FACTION_ASSET_CATEGORIES.force,
      damage: {
        dice: '1d4',
        add: 1,
      },
    },
    counter: {
      dice: '1d4',
    },
  },
  ventureCapital: {
    key: 'ventureCapital',
    category: FACTION_ASSET_CATEGORIES.wealth,
    rating: 6,
    hp: 10,
    cost: 15,
    techLevel: 4,
    type: FACTION_ASSET_TYPES.facility,
    attack: {
      attack: FACTION_ASSET_CATEGORIES.wealth,
      defense: FACTION_ASSET_CATEGORIES.wealth,
      damage: {
        dice: '2d6',
        add: 1,
      },
    },
    notes: [FACTION_ASSET_NOTES.specialAction],
  },
};
